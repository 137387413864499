@use "@angular/material" as mat;
@mixin mat-tab-nav-bar($theme) {
    .mat-tab-nav-bar,
    .mat-tab-header {
        border-bottom: none;
    }
    .mat-tab-list,
    .mat-tab-nav-bar {
        .mat-ink-bar {
            height: 0;
        }
        .mat-tab-label,
        .mat-tab-link {
            opacity: 1;
            border-bottom: 5px solid transparent;
            color: var(--color-black);
        }
        &.mat-primary {
            &.documents {
                .cdk-focused,
                .mat-tab-label-active {
                    &:not(:has(.mat-badge)) {
                        border-bottom: 5px solid var(--color-blue-500);
                    }

                    &.all {
                        &:has(.mat-badge) {
                            background-color: var(--color-state-all-bg) !important;
                            color: var(--color-white) !important;

                            &::before {
                                border-color: var(--color-state-all) !important;
                                background-color: var(--color-state-all) !important;
                            }

                            .mat-badge-content {
                                color: var(--color-white) !important;
                            }
                        }
                    }
                    &.draft {
                        &:has(.mat-badge) {
                            background-color: var(--color-state-draft-bg) !important;
                            color: var(--color-white) !important;

                            &::before {
                                border-color: var(--color-state-draft) !important;
                                background-color: var(--color-state-draft) !important;
                            }

                            .mat-badge-content {
                                color: var(--color-white) !important;
                            }
                        }

                    }
                    &.review {
                        &:has(.mat-badge) {
                            background-color: var(--color-state-review-bg) !important;
                            color: var(--color-white) !important;

                            &::before {
                                border-color: var(--color-state-review) !important;
                                background-color: var(--color-state-review) !important;
                            }

                            .mat-badge-content {
                                color: var(--color-white) !important;
                            }
                        }
                    }
                    &.completed {
                        &:has(.mat-badge) {
                            background-color: var(--color-state-completed-bg) !important;
                            color: var(--color-white) !important;

                            &::before {
                                border-color: var(--color-state-completed) !important;
                                background-color: var(--color-state-completed) !important;
                            }

                            .mat-badge-content {
                                color: var(--color-white) !important;
                            }
                        }
                    }
                    &.signing {
                        &:has(.mat-badge) {
                            background-color: var(--color-state-signing-bg) !important;
                            color: var(--color-white) !important;

                            &::before {
                                border-color: var(--color-state-signing) !important;
                                background-color: var(--color-state-signing) !important;
                            }

                            .mat-badge-content {
                                color: var(--color-white) !important;
                            }
                        }
                    }
                    &.signed {
                        &:has(.mat-badge) {
                            background-color: var(--color-state-signed-bg) !important;
                            color: var(--color-white) !important;

                            &::before {
                                border-color: var(--color-state-signed) !important;
                                background-color: var(--color-state-signed) !important;
                            }

                            .mat-badge-content {
                                color: var(--color-white) !important;
                            }
                        }
                    }
                }
                .mat-tab-link {
                    &:has(.mat-badge)::before {
                        border-color: black;
                    }
                    &.draft {
                        &:has(.mat-badge)::before {
                            border-color: var(--color-state-draft) !important;
                        }
                    }
                    &.review {
                        &:has(.mat-badge)::before {
                            border-color: var(--color-state-review) !important;
                        }
                    }
                    &.completed {
                        &:has(.mat-badge)::before {
                            border-color: var(--color-state-completed) !important;
                        }
                    }
                    &.signing {
                        &:has(.mat-badge)::before {
                            border-color: var(--color-state-signing) !important;
                        }
                    }
                    &.signed {
                        &:has(.mat-badge)::before {
                            border-color: var(--color-state-signed) !important;
                        }
                    }
                }
            }
            &.templates {
                .mat-tab-label-active {
                    border-bottom: 5px solid var(--color-blue-500);
                }
            }
            &.clauses {
                .mat-tab-label-active {
                    border-bottom: 5px solid var(--color-blue-500);
                }
            }
            &.administration {
                .mat-tab-label-active {
                    border-bottom: 5px solid var(--color-blue-500);
                }
            }
        }
        &.menu,
        &.landing-tabs {
            &.ctbox {
                background-color: var(--color-blue-700);
            }
            border-bottom: none;
            overflow: visible;
            padding: 0;
            .disabled {
                opacity: 0.5 !important;
            }
            .mat-tab-links {
                .mat-tab-label,
                .mat-tab-link {
                    color: var(--color-white);
                    font-size: 16px;
                    font-weight: 300;
                    opacity: 1;
                    text-decoration: none !important;
                    border: 0;

                    &-active {
                        background-color: var(--color-blue-500);
                    }
                    &.free {
                        background-color: var(--color-red-500);
                        color: var(--color-white);
                        &.active {
                            background-color: var(--color-red-500);
                        }
                    }
                    &:hover {
                        background-color: var(--color-blue-500);
                    }
                }
            }
            .mat-ink-bar {
                background-color: inherit;
                height: 0;
            }
            &.Small,
            &.XSmall {
                width: 100%;
                .mat-tab-links {
                    flex-direction: column;
                    justify-content: center;
                    .free {
                        margin-left: 0;
                    }
                }
            }
        }
        &.menu {
            width: 100vw;
            .mat-tab-links {
                .mat-tab-label,
                .mat-tab-link {
                    min-height: 32px;
                    max-height: 40px;
                    min-width: initial;
                    &.tab-dashboard {
                        &.active,
                        &:hover {
                            background-color: var(--color-blue-500);
                        }
                    }
                    &.tab-contracts {
                        &.active,
                        &:hover {
                            background-color: var(--color-blue-500);
                        }
                    }
                    &.tab-templates {
                        &.active,
                        &:hover {
                            background-color: var(--color-blue-500);
                        }
                    }
                    &.tab-clauses {
                        &.active,
                        &:hover {
                            background-color: var(--color-blue-500);
                        }
                    }
                    &.tab-administration {
                        border-left: 1px solid var(--color-white);
                    }
                    &.tab-administration {
                        border-left: 1px solid var(--color-white);
                        &.ctbox {
                            margin-left: auto;
                        }
                    }
                    &.logo {
                        &.Small,
                        &.XSmall {
                            display: none;
                        }
                        background-color: var(--color-text);
                        height: 40px;
                        position: absolute;
                        right: 0;
                        width: 218px;
                    }
                }
            }
            &.Medium,
            &.Small,
            &.XSmall {
                .mat-tab-links {
                    justify-content: space-between;
                    .mat-tab-label,
                    .mat-tab-link {
                        min-width: auto;
                        flex: 1;
                        &.logo,
                        &.tab-administration {
                            position: relative !important;
                            right: 0;
                        }
                    }
                }
            }
        }
        &.templates,
        &.documents,
        &.clauses,
        &.administration {
            .mat-tab-link {
                padding: 0;
                height: 48px;
                min-height: 40px;
                min-width: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                gap: 4px;
                margin: 0 24px 0 0;
                padding: 4px 0 0 0;

                &:has(.mat-badge) {
                    height: initial;
                    min-height: auto;
                    padding: 8px 12px;
                    border-radius: $radius-md;
                    border: none;
                    margin: 0 8px 0 0;

                    &::before {
                        display: block;
                        width: 16px;
                        height: 16px;
                        background-color: white;
                        content: '';
                        position: initial;
                        margin: 0 4px 0 0;
                        border-radius: 50%;
                        border: 3px solid black;
                    }
                }
            }
        }
    }
}
