@use "@angular/material" as mat;
@mixin mat-menu($theme) {
    .mat-menu-item {
        &.save {
            height: 32px;
            line-height: 32px;
        }
    }
    .mat-menu-panel {
        min-height: auto;
        max-width: 100%;
        &.help-body {
            .mat-menu-content:not(:empty) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        &.landing-tabs {
            width: 90vw;
            .mat-menu-content:not(:empty) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        &.document-state {
            max-width: min-content;
            overflow: hidden;
            .mat-menu-content:not(:empty) {
                padding-top: 0;
                padding-bottom: 0;
            }
            .mat-menu-item {
                height: auto;
            }
            .mat-icon {
                margin-right: 0;
            }
        }
        &.save-container {
            max-width: min-content;
        }
        &.filter-state {
            .mat-menu-content {
                display: flex;
                flex-direction: column;
                padding: 8px 16px;
            }
        }
    }
    .document-state {
        max-width: 180px;
        overflow: hidden;
        &.mat-menu-trigger {
            &.disabled {
                pointer-events: none;
            }
        }
    }

    .link {
        &-app {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 16px;
        }
        &-img {
            height: 24px !important;
            width: 24px !important;
        }
        &-text {
            display: block;
            color: var(--color-text);
        }
    }

    .user-data {
        &-details {
            display: flex;
            flex-direction: column;
            gap: 8px;
            min-width: 296px;
            padding: 16px;
            text-align: center;
            .full-name,
            .email {
                align-items: center;
                display: flex;
                font-size: 14px;
                justify-content: center;
            }
        }
    }
}
