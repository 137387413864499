
.title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    min-height: $ctbox-title-height;
    padding: 0 24px;

    &-info {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-left: auto;
    }

    .document-state {
        background: var(--color-white);
    }
}
.ctbox-edit-name {
    display: block;
    max-width: 70%;

    &.head {
        align-items: center;
        display: flex;
        max-width: 100%;
        gap: 8px;
        padding-right: 8px;
        width: auto;
    }
}
