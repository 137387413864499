// This file contains very basic styles.

/* Set up a decent box model on the root element */

html {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden !important;
}

/* Basic typography style for copy text */

body {
    color: var(--color-text) !important;
    font-family: var(--font-family-text) !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    height: 100% !important;
    padding: 0 !important;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since '*' has a specificity of 0, it does not override the 'html' value
 * making all elements inheriting from the root box-sizing value
 */

*,
*::before,
*::after {
    box-sizing: inherit;
    @include custom-scrollbar;
}

/* Basic styles for links */
.link {
    color: var(--color-link);
}
.link,
a {
    text-decoration: none;
    &:link,
    &:active,
    &:focus {
        color: var(--color-link);
    }
    &:visited {
        color: var(--color-link);
    }
    &:hover {
        color: var(--color-link-hover);
        text-decoration: underline;
    }
}
