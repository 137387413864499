/// Media Queries

$breakpoints: ("XXS": 600px,
    "XS": 600px,
    "S": 960px,
    "M": 1280px,
    "L": 1920px,
) !default;

@mixin media($breakpoint) {
    @media (max-width: (map-get($breakpoints, $breakpoint) - 1)) {
        @content;
    }
}

/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name. Request from Angular BreakpointObserver
@mixin media-breakpoint-up($breakpoint) {
    @if $breakpoint=="XSmall" {

        .XSmall &,
        .Small &,
        .Medium &,
        .Large &,
        .XLarge & {
            @content;
        }
    }

    @if $breakpoint=="Small" {

        .Small &,
        .Medium &,
        .Large &,
        .XLarge & {
            @content;
        }
    }

    @if $breakpoint=="Medium" {

        .Medium &,
        .Large &,
        .XLarge & {
            @content;
        }
    }

    @if $breakpoint=="Large" {

        .Large &,
        .XLarge & {
            @content;
        }
    }

    @if $breakpoint=="XLarge" {
        .XLarge & {
            @content;
        }
    }
}

/// Sizes
@mixin sizes($gap: false, $breakpoint: true) {
    @for $i from 1 through 20 {
        $size: $i * 5;
        $width: $size * 1%;

        &.size-#{$size} {
            @include media("XS") {
                min-width: 100%;
            }

            @if $breakpoint {
                @include media("S") {
                    $bigWidth: $size * 0.8%;

                    @if $gap {
                        min-width: calc(#{$bigWidth} - #{$gap});
                        max-width: calc(#{$bigWidth} - #{$gap});
                    }

                    @else {
                        min-width: #{$bigWidth};
                        max-width: #{$bigWidth};
                    }
                }
            }

            @if $gap {
                min-width: calc(#{$width} - #{$gap});
                max-width: calc(#{$width} - #{$gap});
            }

            @else {
                min-width: #{$width};
                max-width: #{$width};
            }
        }
    }
}

@mixin position-sticky($top: 0) {
    background-color: var(--color-white);
    position: sticky !important;
    right: 0;
    left: 0;
    transition: all 500ms ease 0s;
    z-index: 1;

    @if $top {
        top: $top;
    }

    @else {
        top: 0;
    }
}

@mixin resizes($gap: false, $breakpoint: true) {
    @for $i from 1 through 20 {
        $size: $i * 5;
        $width: $size * 1%;

        &.resize-#{$size} {
            @include media("XS") {
                width: 100%;
            }

            @if $breakpoint {
                @include media("S") {
                    $bigWidth: $size;

                    @if $gap {
                        width: calc(#{$bigWidth} - #{$gap});
                    }

                    @else {
                        width: #{$bigWidth};
                    }
                }
            }

            @if $gap {
                width: calc(#{$width} - #{$gap});
            }

            @else {
                width: #{$width};
            }
        }
    }
}

@mixin custom-scrollbar(
    $width: 13px,
    $track-border-radius: 5px,
    $thumb-border-radius: 5px,
    $box-shadow-color: black,
    $track-shadow-opacity: 0.3,
    $thumb-shadow-opacity: 0.5
) {
    $box-shadow-inset-soft: inset 0 0 6px rgba($box-shadow-color, $track-shadow-opacity);
    $box-shadow-inset-medium: inset 0 0 6px rgba($box-shadow-color, $thumb-shadow-opacity);

    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-track {
        border-radius: $track-border-radius;
        box-shadow: $box-shadow-inset-soft;
    }

    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: $thumb-border-radius;
        box-shadow: $box-shadow-inset-medium;
    }
}


@mixin border-top-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
}
