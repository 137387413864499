@use "@angular/material" as mat;
@mixin mat-slide-toggle($theme) {
    $typography-config: mat.get-typography-config($theme);
    $mat-slide-toggle-thumb-bg-color: var(--color-false);
    $mat-slide-toggle-thumb-true-bg-color: var(--color-true-light);
    $mat-slide-toggle-bar-bg-color: var(--color-gray-250);
    .mat-slide-toggle {
        height: 32px;
        &.mat-checked .mat-slide-toggle-bar {
            background: $mat-slide-toggle-bar-bg-color;
        }
        .mat-slide-toggle-bar {
            height: 22px;
            width: 48px;
            border-radius: 16px;
            background: $mat-slide-toggle-bar-bg-color;
            &::before {
                content: "\e5cd";
                font-family: var(--font-family-icons);
                position: absolute;
                left: 0;
                color: var(--color-white);
                font-size: 15px;
                width: 24px;
                text-align: center;
            }
        }
        &.neutro:not(.mat-checked) {
            .mat-slide-toggle-thumb {
                background-color: var(--color-white);
            }
        }
        .mat-slide-toggle-thumb {
            height: 16px;
            width: 16px;
            background-color: $mat-slide-toggle-thumb-bg-color;
            color: var(--color-white);
        }
        &.mat-checked  {
            .mat-slide-toggle-thumb {
                background-color: $mat-slide-toggle-thumb-true-bg-color;
            }
        }
        .mat-slide-toggle-thumb-container {
            height: 22px;
            width: 22px;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            border-radius: 16px;
            &::before {
                content: "\e5cd";
                font-family: var(--font-family-icons);
                position: absolute;
                top: 0;
                left: 0;
                color: var(--color-white);
                font-size: 13px;
                width: 23px;
                text-align: center;
            }
            &::after {
                content: "\e5ca";
                font-family: var(--font-family-icons);
                position: absolute;
                top: 0;
                left: 24px;
                color: var(--color-white);
                font-size: 15px;
                width: 24px;
                text-align: center;
            }
        }
        .mat-slide-toggle-ripple {
            display: none;
        }
        &.mat-checked .mat-slide-toggle-thumb-container {
            transform: translate3d(24px, 0, 0);
            &::before {
                content: "\e5ca";
            }
            &::after {
                content: "\e5ca";
                opacity: 0;
                display: none;
            }
        }
        &.mat-disabled {
            &.help {
                opacity: 1 !important;
            }
        }
    }
}
