.not-found {
    color: var(--color-info-secondary);
    font-size: 16px;
    line-height: 1.7em;
    padding: 64px;
    text-align: center;
    width: fit-content;
    margin: auto;

    &.cmc{
        padding: 64px 32px;
        width: auto;
        margin: initial;
    }
}
