.form-group {
    @include sizes();
    align-content: center;
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0;
    &-block {
        display: block;
        margin: 16px 0;
        position: relative;
        &.mb32 {
            margin-bottom: 32px !important;
        }
    }
    &-grid {
        min-width: 100%;
        gap: 16px;
        display: grid;
        &.expirations {
            grid-template-columns: 20% calc(80% - 16px);
        }
        &.sheet {
            display: grid;
            gap: 16px;
            grid-template-columns: auto 180px;
            width: 100%;
            @include media(M) {
                display: flex;
                flex-wrap: wrap;
            }
        }
        &.top {
            margin-top: 24px;
        }
    }
    &-title {
        align-self: center;
        font-weight: 500;
        &.end {
            text-align: right;
        }
        &.top {
            align-self: flex-start;
            margin-top: 6px;
        }
        &.middle-border {
            align-self: flex-start;
            justify-content: space-between;
            min-width: 100%;
            position: relative;
            line-height: 32px;
            &::before {
                content: "";
                position: absolute;
                bottom: 50%;
                border-bottom: var(--border-default);
                width: 100%;
                z-index: -1;
            }
        }
    }
    &-label {
        background-color: var(--color-white);
        font-weight: 500;
        margin-right: 8px;
    }
    &-flex {
        @include sizes($gap: 16px);
        flex-wrap: wrap;
        display: flex;
        align-content: flex-start;
        gap: 16px;
        &.gap0 {
            gap: 0 !important;
        }
        &.gap8 {
            gap: 8px !important;
        }
        &.min100 {
            min-width: 100%;
        }
        &.center {
            align-items: center;
            align-content: center;
        }
        &.inline {
            align-items: stretch;
            display: inline-flex;
            flex-direction: row;
            padding-right: 16px;
        }
        &.frow {
            flex-direction: row;
        }
        &.fcol {
            align-items: flex-start;
            flex-direction: column;
        }
        &.end {
            flex: 1;
            justify-content: flex-end;
        }
        &.button {
            min-width: 180px;
        }
        &.border-group {
            border-left: var(--border-default);
            padding: 8px 0 8px 8px;
        }
        &.border-mail {
            border-left: var(--border-default);
            padding: 0 0 0 16px;
            gap: 16px;
        }
        &.section-check {
            padding: 0 32px;
            margin: 8px 0;
        }
        &.coment {
            max-width: 100%;
            gap: 16px 32px;
            padding: 1em 0;
            width: 100%;
        }
        &.notification {
            padding: 16px 0;
        }
    }
    &-error {
        font-size: 75%;
        bottom: -24px;
        position: absolute;
    }
    .user {
        font-size: 1em;
        &-email {
            margin: 0 8px 0 0;
        }
        &-type {
            color: var(--color-text-secondary);
        }
    }
    &-checkbox {
        margin: 0 0 16px 0;
    }
    &.expirations {
        padding: 2em;
        max-width: 80%;
        margin: 0 auto;
        gap: 16px 32px;
    }
    .mat-label {
        padding-right: 8px;
    }
    .pt-8 {
        padding-top: 8px;
    }
    &.form-container {
        padding: 16px 0;
        gap: 40px;
    }
}
