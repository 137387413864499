.folder-tree {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ctbox-tree {
        display: block;
        border: var(--border-light);
        background-color: var(--tree-background-color);
        height: inherit;
        max-height: calc(100% - 80px);
        min-height: calc(100% - 80px);
        overflow: hidden;
        .mat-tree{
            height: 100%;
            width: 100%;
            overflow: auto;
            display: block;
        }

        &.tree-filter {
            max-height: calc(100% - 10px);
            min-height: calc(100% - 10px);
        }
    }

    &-side-nav {
        display: flex;
        height: inherit;
        width: 100%;
    }

    &-search {
        min-height: 79px;
        padding: 40px 0 0 32px;
    }

    &-container {
        background-color: var(--tree-background-color);
        display: flex;
        flex-direction: column;
        height: inherit;
        padding: 0;
    }

    &-tabs {
        min-height: 40px;
        padding-left: 24px;
    }

    &-result {
        display: block;
        height: inherit;
        max-height: calc(100% - 40px);
        min-height: calc(100% - 40px);

        .ctbox-tree {
            border: none;
        }
    }

    &-result-list {
        height: calc(100% - 40px);
        max-height: calc(100% - 40px);
        min-height: calc(100% - 40px);

        &-inquire {
            height: calc(100% - 40px);
            max-height: calc(100% - 40px);
            min-height: calc(100% - 40px);
            background-color: var(--background-color-inquire)  !important;
        }
    }

    &-filter {
        display: flex;
        justify-content: center;
    }
}
