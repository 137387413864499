@mixin mat-badge($theme) {
    .wf-user-order {
        &.template {
            .mat-badge-content {
                background-color: var(--color-white);
                border: 1px solid var(--color-template);
                color: var(--color-documents);
            }
        }
        &.document {
            .mat-badge-content {
                background-color: var(--color-white);
            }
        }
        &.blocked {
            .mat-badge-content {
                background-color: var(--color-white);
                border: 1px solid var(--color-disabled);
                color: var(--color-disabled);
            }
        }
    }

    .mat-badge {
        &-inline {
            display: inline-flex !important;
            .mat-badge-content {
                font-weight: normal;
                position: initial !important;
            }
        }

        &-content {
            color: var(--color-black);
            background-color: transparent;
            border-radius: 0;
        }
        &-large {
            .mat-badge-content {
                width: auto;
                height: initial;
                line-height: initial;
                font-size: 0.85em;
                font-weight: normal;
                display: block !important;
                position: initial !important;
                &::before {
                    content: '(';
                }
                &::after {
                    content: ')';
                }
            }
        }
        /*&-all {
            .mat-badge-content {
                background-color: var(--color-black);
                //border: 1px solid var(--color-documents);
                color: var(--color-white);
            }
        }
        &-draft {
            .mat-badge-content {
                background-color: var(--color-state-draft) !important;
            }
        }
        &-review {
            .mat-badge-content {
                background-color: var(--color-state-review) !important;
            }
        }
        &-completed {
            .mat-badge-content {
                background-color: var(--color-state-completed) !important;
            }
        }
        &-signing {
            .mat-badge-content {
                background-color: var(--color-state-signing) !important;
            }
        }
        &-signed {
            .mat-badge-content {
                background-color: var(--color-state-signed) !important;
            }
        }*/
    }
}
