.main {
    height: $ctbox-container-height;
    padding: 16px;
    &-new {
        height: $ctbox-main-new-height;
        padding: 0;
        margin: $ctbox-main-new-space-block-start $ctbox-main-new-space-inline-end $ctbox-main-new-space-block-end $ctbox-main-new-space-inline-start;
        background-color: var(--color-white);
        border-radius: var(--radius-lg);
        display: flex;
        flex-direction: column;
        border: var(--border-dark);
        .main {
            &-close {
                position: inherit;
                display: flex;
                justify-content: flex-end;
                padding: 16px;
                margin: 0 0 16px 0;
            }
            &-title {
                text-align: center;
                font-size: 34px;
                font-weight: 300;
                margin:  0 0 40px 0;
            }
            &-subtitle {
                text-align: center;
                font-size: 24px;
                font-weight: 400;
                margin: 0 0 56px 0;
                color: var(--color-primary);
            }
        }
    }
    &.aditional-document {
        padding: 39px 0 100px 0;
    }
    &-close {
        position: absolute;
        right: 29px;
        top: 25px;
        @include media(XS) {
            right: 12px;
            top: 8px;
        }
    }
    &-title {
        text-align: center;
        font-size: 22px;
        @include media(XS) {
            margin: 0 16px;
            line-height: 27px;
        }
    }
    &-subtitle {
        text-align: center;
        font-size: 19px;
        margin: 13px 0 48px 0;
        color: var(--color-text-secondary);
        @include media(XS) {
            margin: 13px 16px 48px 16px;
            line-height: 25px;
        }
    }
}

.ctbox-cards-group {
    align-items: flex-start;
    display: flex;
    gap: 16px;
    justify-content: center;
    .ctbox-card {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 320px;
        &-link {
            align-content: center;
            align-items: center;
            background-color: var(--color-white);
            background-position: center 32px;
            background-repeat: no-repeat;
            border: 2px dashed var(--border-color-default);
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 240px;
            width: 320px;
            padding: 32px;
            border-radius: var(--radius-lg);
            &.cmc {
                &-bp,
                &-bc,
                &-aj {
                    align-items: flex-start;
                    justify-content: flex-start;
                    border-bottom: 4px solid var(--color-santander);
                }
            }
            &-title {
                color: var(--color-text);
                font-size: 19px;
                font-weight: 500;
                outline: none;
                text-decoration: none;
            }
            &-text {
                color: var(--color-text-secondary);
                font-size: 12px;
                min-height: 85%;
                outline: none;
                padding: 24px 0 0 0;
                text-decoration: none;
            }
            &-button {
                align-self: flex-end;
            }
            &.from-tdc {
                &.documents {
                    background-image: url("../../assets/images/all-wk-model.svg");
                }
                &.templates {
                    background-image: url("../../assets/images/all-wk-model.svg");
                }
                &.clauses {
                    background-image: url("../../assets/images/all-wk-model.svg");
                }
            }
            &.from-file {
                &.documents {
                    background-image: url("../../assets/images/all-upload-document.svg");
                }
                &.templates {
                    background-image: url("../../assets/images/all-upload-document.svg");
                }
                &.clauses {
                    background-image: url("../../assets/images/all-upload-document.svg");
                }
                &.cmc {
                    background-image: url("../../assets/images/selection-upload-document.svg");
                }
            }
            &.from-new {
                &.documents {
                    background-image: url("../../assets/images/all-create-document.svg");
                }
                &.templates {
                    background-image: url("../../assets/images/all-create-document.svg");
                }
                &.clauses {
                    background-image: url("../../assets/images/all-create-document.svg");
                }
                &.cmc {
                    background-image: url("../../assets/images/selection-create-document.svg");
                }
            }
            &:focus,
            &:hover {
                outline: none;
                text-decoration: none;
                box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
            }
            &:active {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
            }
            &.help {
                height: 130px;
                width: 120px;
                background-size: 80px;
            }
        }
        &-text {
            color: var(--color-text-secondary);
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            padding: 0 16px;
        }
    }
}
