@mixin mat-dialog($theme) {
    .XL {
        .mat-dialog {
            &-content {
                max-height: calc(85vh - 48px);
                min-height: calc(80vh - 55px);
            }
        }
    }

    .M {
        .mat-dialog {
            &-content {
                max-height: calc(50vh - 48px);
                min-height: calc(50vh - 44px);
            }
        }
    }

    .mat-dialog {
        &-container {
            padding: 16px;
            /* redimensionar modales */
            overflow: auto;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            height: 40px;
        }

        &-title {
            margin: 0;
            padding: 0;
        }

        &-close {
            margin: 0;
            padding: 0;
        }

        &-content {
            margin: 0 -16px;
            padding: 0 16px;
            max-height: calc(80vh - 48px);

            &.border {
                border: var(--border-default);
            }

            &.warning {
                min-width: 30vw;
            }

            &.no-actions {
                height: calc(100% - 64px) !important;
                min-height: calc(100% - 28px) !important;
                max-height: initial !important;
            }

            &.not-overflow {
                overflow: hidden;
            }

            &.tree,
            &.tree-modal {
                border: var(--border-default);
                height: inherit;
                height: 62vh;
                min-height: 62vh;
                max-height: 62vh;
                margin: 0;
                padding: 0;
            }
            .ctbox-tree{
                height: 61vh;
                max-height: inherit;
                overflow: auto;
                border: var(--border-light);
                border-radius: var(--radius-lg);
                margin: 0 0 16px 0;
            }
            &:has(.save-as) {
                .ctbox-tree{
                    height: 58vh;
                    border: none;
                    border-radius: 0;
                }
            }
        }

        &-actions {
            justify-content: flex-end;
            margin: 0;
            min-height: 32px;
            gap: 16px;
        }
    }

    .generic-dialog-block {
        display: block;
        outline: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
    }

    .generic-dialog-content-inherit {
        display: block;
        overflow: auto;
        outline: 0;
        width: 100%;
        height: 100%;
        min-height: inherit;
        max-height: inherit;
    }

    .save-as {
        display: flex;
        flex-direction: column;

        &-version {
            padding: 8px 0 32px 0;
        }

        &-name {
            padding: 8px 0 16px;
            //margin: 0 32px;
        }

        &-tree {
            //border: none;
            //height: 63vh;
            border: var(--border-light);
            border-radius: var(--radius-lg);
            margin: 0 0 16px 0;
        }

        &-tree-search-box {
            display: block;
            height: 32px;
        }

        .ctbox-tree {
            border: none;
            height: calc(63vh - 32px);
            max-height: inherit !important;
            overflow: auto;
        }
    }
}
