.updates {
    &-button {
        display: flex;
        &.warning {
            .mat-button {
                border: 1px solid var(--color-warning);
                &.warning {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right: none;
                    background-color: var(--color-white);
                    color: var(--color-warning);
                }
                &.border-left {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-left: none;
                    background-color: var(--color-white);
                }
            }
        }
    }
    &-menu {
        max-width: 100% !important;
        width: 100%;
        height: 100%;
    }
    &-content {
        align-content: center;
        align-items: flex-start;
        column-gap: 32px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 8px;
    }
}
