.ctbox-search {
    flex: 2;
    padding: 24px;

    &-bar {
        align-items: center;
        display: flex;
        gap: 16px;
        justify-content: space-between;
        padding: 0 var(--body-space-inline-start) 0 var(--body-space-inline-end);
        min-height: $ctbox-actionbar-height;
    }

    &-box {
        display: flex;
        align-items: center;

        .ctbox & .mat-form-field {
            line-height: 22px;
            &.mat-form-field-appearance-outline .mat-form-field-outline-end {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
            &.mat-form-field-appearance-outline .mat-form-field-outline-start {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }
    }

    &-actions {
        display: flex;
        align-items: flex-end;

        &-search {
            align-items: center;
            display: flex;
            height: 27px;
            button.mat-focus-indicator.mat-button.mat-button-base.mat-button-icon.mat-secondary {
                background-color: var(--color-primary);
                width: 40px;
                height: 32px;
                .mat-icon {
                    color: var(--color-white);
                    font-size: 24px;
                    line-height: inherit;
                }
            }
        }
    }

    &-logo {
        height: 40px !important;
        width: 40px !important;
    }

    &-title {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 40px;
    }

    &-form {
        background-color: var(--color-white);

        &.big {
            @include media-breakpoint-up("XSmall") {
                min-width: 150px;
            }

            @include media-breakpoint-up("Medium") {
                min-width: 300px;
            }

            @include media-breakpoint-up("Large") {
                min-width: 450px;
            }
        }
    }

    &-result {
        .search-list {

            .mat-list-item,
            .mat-list-option {
                &:hover {
                    background: none;
                }
            }

            .mat-list-item:nth-child(2n) {
                height: 36px;
                background-color: var(--shadow-background);
            }

            .mat-list-text {
                flex-direction: row !important;
                align-items: center;
                gap: 8px;
                font-size: 13px;
            }
        }
    }


}

.highlight {
    background-color: var(--color-highlight);
    font-style: normal;
    padding: 4px 0;
    &-navigated {
        background-color: var(--color-highlight-navigated);
        font-style: normal;
        padding: 4px 0;
    }
}

span:has(>.highlight-navigated) {
    background-color: var(--color-background-highlight);
}
span:has(>.highlight) {
    background-color: var(--color-background-highlight);
}

.show-results {
    display: none; //cambiar a flex si se recuperan la cantidad de resultados
    align-items: center;
    border-right: var(--border-default);
    height: 29px;
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 3px;
    block-size: 29px;
    writing-mode: horizontal-tb;
}
