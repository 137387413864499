$radius-sm: 3px;
$radius-md: 4px;
$radius-lg: 5px;
///
$ctbox-container-body-space-block-start: 0;
$ctbox-container-body-space-block-end: 24px;
$ctbox-container-body-space-inline-start: 24px;
$ctbox-container-body-space-inline-end: 24px;
///
$ctbox-wysiwyg-space-block-start: 0;
$ctbox-wysiwyg-space-block-end: 7px;
$ctbox-wysiwyg-space-inline-start: 8px;
$ctbox-wysiwyg-space-inline-end: 8px;
///
$ctbox-main-new-space-block-start: 24px;
$ctbox-main-new-space-block-end: 24px;
$ctbox-main-new-space-inline-start: 24px;
$ctbox-main-new-space-inline-end: 24px;
