@use "@angular/material"as mat;

:root {
    --color-primary: #{$color-primary};
    --color-primary-dark: #{$color-primary-dark};
    --color-accent: #{$color-accent};
    --color-dashboard: #{$color-dashboard};
    --color-documents: #{$color-documents};
    --color-templates: #{$color-templates};
    --color-clauses: #{$color-clauses};
    --color-administration: #{$color-administration};
    --color-state-all: #{$color-state-all};
    --color-state-draft: #{$color-state-draft};
    --color-state-review: #{$color-state-review};
    --color-state-completed: #{$color-state-completed};
    --color-state-signing: #{$color-state-signing};
    --color-state-signed: #{$color-state-signed};
    --color-state-all-bg: #{$color-state-all-bg};
    --color-state-draft-bg: #{$color-state-draft-bg};
    --color-state-review-bg: #{$color-state-review-bg};
    --color-state-completed-bg: #{$color-state-completed-bg};
    --color-state-signing-bg: #{$color-state-signing-bg};
    --color-state-signed-bg: #{$color-state-signed-bg};
    --color-state-draft-pie: #{$color-state-draft-pie};
    --color-state-review-pie: #{$color-state-review-pie};
    --color-state-completed-pie: #{$color-state-completed-pie};
    --color-state-signing-pie: #{$color-state-signing-pie};
    --color-state-signed-pie: #{$color-state-signed-pie};
    --box-shadow: #{$box-shadow};
    --shadow-background: #{$shadow-background};
    --border-color-default: #{$border-color-default};
    --border-default: #{$border-default};
    --border-color-dark: #{$border-color-dark};
    --border-dark: #{$border-dark};
    --border-color-light: #{$border-color-light};
    --border-light: #{$border-light};
    --color-white: #{$color-white};
    --color-black: #{$color-black};
    --color-true-light: #{$color-true-light};
    --color-true: #{$color-true};
    --color-false: #{$color-false};
    --color-info: #{$color-info};
    --color-info-secondary: #{$color-info-secondary};
    --color-info-dark: #{$color-info-dark};
    --color-text: #{$color-text-default};
    --color-text-secondary: #{$color-text-secondary};
    --color-link: #{$color-link-default};
    --color-link-hover: #{$color-link-hover};
    --color-disabled: #{$color-disabled};
    --color-update: #{$color-update};
    --background-color-disabled: #{$background-color-disabled};
    --background-color-deleted: #{$background-color-deleted};
    --background-color-insert: #{$background-color-insert};
    --background-color-input: #{$background-color-input};
    --tree-background-color: #{$tree-background-color};
    --tree-node-selected-background-color: #{$tree-node-selected-background-color};
    --color-deleted: #{$color-deleted};
    --color-delete: #{$color-delete};
    --color-created: #{$color-created};
    --color-success: #{$color-success};
    --color-error: #{$color-error};
    --color-warning: #{$color-warning};
    --color-warning-soft: #{$color-warning-soft};
    --color-title: #{$color-title};
    --color-landing: #{$color-landing};
    --color-santander: #{$color-santander};
    --color-santander-dark: #{$color-santander-dark};
    --color-santander-black: #{$color-santander-black};
    --color-highlight: #{$color-highlight};
    --color-folder-icon: #{$color-folder-icon};

    ///
    --color-red-500: #{map-get($mat-cbox-red, 500)};
    --color-red-600: #{map-get($mat-cbox-red, 600)};
    --color-red-700: #{map-get($mat-cbox-red, 700)};
    --color-red-800: #{map-get($mat-cbox-red, 800)};
    ///
    --color-blue-100: #{map-get($mat-cbox-blue, 100)};
    --color-blue-200: #{map-get($mat-cbox-blue, 200)};
    --color-blue-300: #{map-get($mat-cbox-blue, 300)};
    --color-blue-400: #{map-get($mat-cbox-blue, 400)};
    --color-blue-500: #{map-get($mat-cbox-blue, 500)};
    --color-blue-600: #{map-get($mat-cbox-blue, 600)};
    --color-blue-700: #{map-get($mat-cbox-blue, 700)};
    --color-blue-800: #{map-get($mat-cbox-blue, 800)};
    --color-blue-900: #{map-get($mat-cbox-blue, 900)};
    --color-blue-A50: #{map-get($mat-cbox-blue, A50)};
    --color-blue-A100: #{map-get($mat-cbox-blue, A100)};
    --color-blue-A200: #{map-get($mat-cbox-blue, A200)};
    --color-blue-A400: #{map-get($mat-cbox-blue, A400)};
    --color-blue-A700: #{map-get($mat-cbox-blue, A700)};
    ///
    --color-green-50: #{map-get($mat-cbox-green, 50)};
    --color-green-100: #{map-get($mat-cbox-green, 100)};
    --color-green-200: #{map-get($mat-cbox-green, 200)};
    --color-green-300: #{map-get($mat-cbox-green, 300)};
    --color-green-400: #{map-get($mat-cbox-green, 400)};
    --color-green-500: #{map-get($mat-cbox-green, 500)};
    --color-green-700: #{map-get($mat-cbox-green, 700)};
    ///
    --color-yellow-50: #{map-get($mat-cbox-yellow, 50)};
    --color-yellow-100: #{map-get($mat-cbox-yellow, 100)};
    --color-yellow-200: #{map-get($mat-cbox-yellow, 200)};
    --color-yellow-500: #{map-get($mat-cbox-yellow, 500)};
    --color-yellow-600: #{map-get($mat-cbox-yellow, 600)};
    --color-yellow-700: #{map-get($mat-cbox-yellow, 700)};
    --color-yellow-800: #{map-get($mat-cbox-yellow, 800)};
    --color-yellow-900: #{map-get($mat-cbox-yellow, 900)};
    ///
    --color-gray-100: #{map-get($mat-cbox-gray, 100)};
    --color-gray-200: #{map-get($mat-cbox-gray, 200)};
    --color-gray-250: #{map-get($mat-cbox-gray, 250)};
    --color-gray-300: #{map-get($mat-cbox-gray, 300)};
    --color-gray-400: #{map-get($mat-cbox-gray, 400)};
    --color-gray-500: #{map-get($mat-cbox-gray, 500)};
    --color-gray-600: #{map-get($mat-cbox-gray, 600)};
    --color-gray-700: #{map-get($mat-cbox-gray, 700)};
    --color-gray-800: #{map-get($mat-cbox-gray, 800)};
    --color-gray-900: #{map-get($mat-cbox-gray, 900)};
    ///
    $root-typography-config: $typography;
    $root-font-family-text: mat.font-family($root-typography-config);
    $root-font-family-secondary: mat.font-family($root-typography-config, "display-3");
    $root-font-family-icons: mat.font-family($root-typography-config, "display-4");
    --font-family-text: #{$root-font-family-text};
    --font-family-secondary: #{$root-font-family-secondary};
    --font-family-icons: #{$root-font-family-icons};
    ///
    --radius-sm: #{$radius-sm};
    --radius-md: #{$radius-md};
    --radius-lg: #{$radius-lg};
    ///
    --body-space-block-start: #{$ctbox-container-body-space-block-start};
    --body-space-block-end: #{$ctbox-container-body-space-block-end};
    --body-space-inline-start: #{$ctbox-container-body-space-inline-start};
    --body-space-inline-end: #{$ctbox-container-body-space-inline-end};
    ///
    --wysiwyg-space-block-start: #{$ctbox-wysiwyg-space-block-start};
    --wysiwyg-space-block-end: #{$ctbox-wysiwyg-space-block-end};
    --wysiwyg-space-inline-start: #{$ctbox-wysiwyg-space-inline-start};
    --wysiwyg-space-inline-end: #{$ctbox-wysiwyg-space-inline-end};
}
