.tabs {
    &-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        min-height: $ctbox-tabsbar-height;
        padding: 0 var(--body-space-inline-start) 0 var(--body-space-inline-end);
        .cmc-santander & {
            border-bottom: var(--border-default);
            padding: 0 16px;
        }

        .cmc-santander & {
            padding: 0 16px;
        }

        &.sticky {
            @include position-sticky();
        }
    }
    &-action {
        display: flex;
    }
    &.width-title {
        align-items: initial;
    }
    &-list {
        border-bottom: none;
    }
    &-info {
        @include media(S) {
            flex-wrap: wrap;
        }
        align-items: center;
        display: flex;
        gap: 16px;
        margin: 0 16px;
        &-alert {
            border-right: var(--border-default);
            padding-right: 16px;
        }
        &-help {
            border-left: var(--border-default);
            padding-left: 8px;
        }
        &-help,
        &-alert {
            @include media(XS) {
                border: none;
                justify-content: flex-end;
                margin: 0;
                padding: 8px 0;
            }
        }
        .notification {
            white-space: nowrap;
        }
    }
    .buttonbar {
        padding-right: 16px;
    }
}
