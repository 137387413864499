@keyframes kf-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0)
    }
}

@keyframes kf-refresh {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes kf-rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg)}
}
