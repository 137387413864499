@mixin mat-checkbox($theme) {
    .mat-checkbox {
        &.filter {
            .mat-checkbox-label {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                gap: 8px;
            }
        }

        &-frame {
            border-width: 1px !important;
        }

        &.checklist-leaf-node {
            background-color: var(--color-white);
        }

        &-disabled {
            .mat-checkbox-frame {
                background-color: var(--shadow-background);
            }
        }
        &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
        &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
            background-color: var(--color-primary);
        }
    }
}
