.signature {
    display: flex;
    gap: 8px;
    &-form {
        height: 100%;
    }
    &.wrapper-content {
        border: none;
        height: calc(100% - 8px);
    }
    .ctbox-border {
        margin: 0;
        border: var(--border-light);
        padding: 20px;
    }
    &-sign-rol {
        display: none;
    }
    &-sign-placement {
        height: 100px;
        vertical-align: bottom;
    }
    &-in-editor {
        margin: 5px;
        padding: 5px;
        border: none;
        background: var(--shadow-background);
        width: 300px;
        height: 140px;
        display: inline-block;
    }
    &-container {
        flex: 1;
        overflow: auto;
    }
    &-title {
        display: flex;
        width: 100%;
        margin-bottom: 16px;
        &-label {
            font-size: 16px;
            font-weight: 500;
        }
    }
    &-text {
        min-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-message {
        display: flex;
        align-items: center;
    }
    &-warning {
        color: var(--color-error);
    }
    &-signer {
        margin: 24px 0;
    }
    &-type {
        margin: 0 0 16px 3px;
        &-number {
            border: var(--border-default);
            border-radius: 20px;
            display: inline-block;
            font-size: 13px;
            margin: 0 8px 0 0;
            text-align: center;
            height: 20px;
            line-height: 20px;
            width: 20px;
        }
        &-name {
            font-size: 15px;
            font-weight: 500;
        }
    }
    &-data {
        overflow: hidden;
        &-left {
            width: 50%;
            float: left;
        }
        &-right {
            width: 50%;
            float: right;
        }
        &-user {
            margin: 0 0 12px 30px;
            .title {
                font-weight: 500;
                margin: 0 0 2px 0;
                font-size: 14px;
            }
            .subtitle {
                color: var(--color-text-secondary);
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 16px;
            }
            .pending {
                color: var(--color-state-signing);
                font-weight: 400;
            }
        }
    }
    &-info {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        gap: 8px;
        min-width: 25vw;
        &-block {
            label {
                margin: 0 16px 0 0;
                color: var(--color-gray-700);
                vertical-align: 3px;
            }
            input {
                outline: none;
                margin-left: 0;
                margin-right: 8px;
            }
            .title {
                margin-bottom: 16px;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }
        &-expiration {
            input[type="text"] {
                width: 30px;
                height: 20px;
                vertical-align: middle;
                border: var(--border-default);
            }
        }
        &-message {
            flex: 1;
            display: flex;
            flex-direction: column;
            .form-group-flex {
                flex: 1;
            }
        }
    }
    &-list {
        min-height: 60px;
        overflow: hidden;
        display: block;
        &-item {
            padding: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            box-sizing: border-box;
            cursor: move;
            &:has(.signature-inline) {
                padding-left: 0;
                .signer {
                    padding: 0 !important;
                }
            }
        }
    }
}

.cke_widget_wrapper_signature-in-editor {
    display: inline-block;
}

div.signature-in-editor {
    border: 2px solid var(--color-templates);
}
