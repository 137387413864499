.action {
    &-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        min-width: calc(100% - 32px);
        min-height: $ctbox-actionbar-height;
        padding: 0 var(--body-space-inline-start) 0 var(--body-space-inline-end);
        .cmc-santander & {
            padding: 0 16px;
        }

        .cmc-santander {
            padding: 0 16px;
        }

        &--fixed {
            margin: -$ctbox-actionbar-height 0 0 0;
        }

        &.paginator {
            min-height: $ctbox-tabsbar-paginator-height !important;
            border-bottom: none;
        }

        &.title-bar-tabs {
            min-height: $ctbox-title-bar-tabs-height !important;
        }

        &.head {
            border-bottom: none;
        }

        &.sticky {
            @include position-sticky();
        }

        @include media(XS) {
            flex-direction: column;
            padding: 0 8px;
            min-height: auto;
        }
    }

    &-info {
        align-items: center;
        display: flex;
        flex-direction: row;

        gap: 8px;
        margin-left: auto;

        @include media(XS) {
            flex-wrap: wrap;
        }
    }

    &-title {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;

        .title-name {
            max-width: 100% !important;
        }

        &.center {
            justify-content: center;
        }

        @include media(XS) {
            flex-wrap: wrap;
        }

        &-name {
            color: var(--color-santander-dark);
            font-size: 1.2em;
            font-weight: 500;
            line-height: 1.2em;
            margin-left: 16px;
            text-align: center;
        }
        &-status {
            color: var(--color-santander-dark);
            font-style: italic;
            font-weight: 400;
        }

        .notification-to-user {
            justify-content: center;
        }

    }
    &-search-cbox {
        display: flex;
        gap: 12px;
        > app-search-box {
            width: 450px;
        }
    }

}


