.actions {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-height: $ctbox-actions-height;
    padding: 0 8px;
    margin: 0 24px;
    background: var(--color-white);
    border-top-left-radius: var(--radius-lg);
    border-top-right-radius: var(--radius-lg);
    border: var(--border-dark);
    border-bottom: none;
    &-bar {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        width: 100%;
        min-height: $ctbox-actionbar-height;
        padding: 0 0 12px 0;
    }
    &-title {
        align-items: center;
        display: flex;
        width: 50%;
    }
    &-buttons {
        display: flex;
        flex: 1;
        gap: 8px;
        justify-content: flex-end;
    }
    &-filters {
        padding: 24px;
    }
    .save-container {
        display: flex;
    }
    .notification-to-user {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
    }
}
