@use "@angular/material"as mat;
$typography: mat.define-legacy-typography-config($font-family: "Roboto, Arial, sans-serif",
    $headline: mat.define-typography-level(32px, 48px, 700),
    $body-1: mat.define-typography-level(14px, 24px, 400),
    $button: mat.define-typography-level(14px, 24px, 400),
    $title: mat.define-typography-level(22px, 32px, 500),
    $display-3: mat.define-typography-level($font-family: "Karnov Display",
        $font-weight: 400,
        $font-size: 1rem,
        $line-height: 1,
        $letter-spacing: normal),
    $display-4: mat.define-typography-level($font-family: "Material Symbols Outlined",
        $font-weight: 400,
        $font-size: 1rem,
        $line-height: 1,
        $letter-spacing: normal));

@font-face {
    font-family: "Karnov Display";
    src        : url("../../assets/fonts/Karnov-Display.woff2");
    font-weight: 400;
    font-style : normal;
}
