.ctbox {
    .header {
        &-logo {
            &.ctbox {
                display: flex;
                align-items: center;
            }
        }
    }
    .mat-paginator {
        background: transparent !important;
    }
}
